import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import groover from "../../Assets/Projects/groover.png";
import storage from "../../Assets/Projects/StorageSculptor.png";
import formflow from "../../Assets/Projects/formflow.png";
import shellify from "../../Assets/Projects/shellify.png";
import connectify from "../../Assets/Projects/connectify.png";
import etcdkvstore from "../../Assets/Projects/etcdkvstore.png";
import iotforhire from "../../Assets/Projects/iotforhire.png";
import portfolio from "../../Assets/Projects/portfolio.png";
// import suicide from "../../Assets/Projects/suicide.png";
// import bitsOfCode from "../../Assets/Projects/blog.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
        <Col md={4} className="project-card">
            <ProjectCard
              imgPath={connectify}
              isBlog={false}
              title="Connectify"
              description="In this project, we implement a File Transfer Protocol (FTP) system for efficient file exchange over a network."
              ghLink="https://github.com/Sohoxic/Connectify"
              // demoLink="https://www.npmjs.com/package/use-formflow"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={shellify}
              isBlog={false}
              title="Shellify"
              description="Shellify is a command-line interface that Unix-based system features like file redirection, pipes, command chaining, and a history navigation system."
              ghLink="https://github.com/Sohoxic/Shellify"
              // demoLink="https://www.npmjs.com/package/use-formflow"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={etcdkvstore}
              isBlog={false}
              title="EtcdKVStore"
              description="A distributed key-value store leveraging etcd, featuring comprehensive CRUD operations, error handling, and an intuitive command-line interface (CLI) for enhanced user interaction."
              ghLink="https://github.com/Sohoxic/EtcdKVStore"
              // demoLink="https://www.npmjs.com/package/use-formflow"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={iotforhire}
              isBlog={false}
              title="IoT For Hire"
              description="ReactJS platform enabling students to rent IoT components conveniently. Powered by a Node.js and Express.js backend, it seamlessly integrates with Google Sheets API to provide real-time inventory access. The platform addresses the challenge of sourcing reliable and cost-effective IOT components by facilitating the rental of such components."
              ghLink="https://iotforhire.vercel.app"
              // demoLink="https://www.npmjs.com/package/use-formflow"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={formflow}
              isBlog={false}
              title="FormFlow"
              description="The useForm hook provides an abstraction for managing form states, validation, and submissions. It simplifies the process of handling form inputs and reduces boilerplate for developers."
              ghLink="https://github.com/Sohoxic/FormFlow"
              // demoLink="https://www.npmjs.com/package/use-formflow"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={portfolio}
              isBlog={false}
              title="Portfolio"
              description="This React JS-powered personal portfolio website showcases my skills and experience in a professional and engaging manner.

              "
              ghLink="https://github.com/Sohoxic/Portfolio"
              demoLink="sarkar.vercel.app/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={storage}
              isBlog={false}
              title="StorageSculptor"
              description="StorageSculptor is a custom React hook to simplify the use of the Local Storage API with React's state management system."
              ghLink="https://github.com/Sohoxic/Portfolio"
              // demoLink="sarkar.vercel.app/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={groover}
              isBlog={false}
              title="Groover"
              description="This is my attempt in making a music streaming platform. (no more irritation from adds)"
              ghLink="https://github.com/Sohoxic/Groover"
              demoLink="groover.netlify.app/"
            />
          </Col>
        </Row>
    {/* 
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="Bits-0f-C0de"
              description="My personal blog page build with Next.js and Tailwind Css which takes the content from makdown files and renders it using Next.js. Supports dark mode and easy to write blogs using markdown."
              ghLink="https://github.com/soumyajit4419/Bits-0f-C0de"
              demoLink="https://blogs.soumya-jit.tech/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="Editor.io"
              description="Online code and markdown editor build with react.js. Online Editor which supports html, css, and js code with instant view of website. Online markdown editor for building README file which supports GFM, Custom Html tags with toolbar and instant preview.Both the editor supports auto save of work using Local Storage"
              ghLink="https://github.com/soumyajit4419/Editor.io"
              demoLink="https://editor.soumya-jit.tech/"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={leaf}
              isBlog={false}
              title="Plant AI"
              description="Used the plant disease dataset from Kaggle and trained a image classifer model using 'PyTorch' framework using CNN and Transfer Learning with 38 classes of various plant leaves. The model was successfully able to detect diseased and healthy leaves of 14 unique plants. I was able to achieve an accuracy of 98% by using Resnet34 pretrained model."
              ghLink="https://github.com/soumyajit4419/Plant_AI"
              demoLink="https://plant49-ai.herokuapp.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={suicide}
              isBlog={false}
              title="Ai For Social Good"
              description="Using 'Natural Launguage Processing' for the detection of suicide-related posts and user's suicide ideation in cyberspace  and thus helping in sucide prevention."
              ghLink="https://github.com/soumyajit4419/AI_For_Social_Good"
              // demoLink="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley" <--------Please include a demo link here
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="Face Recognition and Emotion Detection"
              description="Trained a CNN classifier using 'FER-2013 dataset' with Keras and tensorflow backened. The classifier sucessfully predicted the various types of emotions of human. And the highest accuracy obtained with the model was 60.1%.
              Then used Open-CV to detect the face in an image and then pass the face to the classifer to predict the emotion of a person."
              ghLink="https://github.com/soumyajit4419/Face_And_Emotion_Detection"
              // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here 
            />
          </Col>
        </Row>
  */}
      </Container>
    </Container>
  );
}

export default Projects;
