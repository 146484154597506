import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Github from "./Github";
import Techstack from "./Techstack";
import Aboutcard from "./AboutCard";
import laptopImg from "../../Assets/about.png";
import Toolstack from "./Toolstack";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

function About() {
  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "0px",
              paddingBottom: "0px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              Know Who <strong className="purple">I'M</strong>
            </h1>
            <Aboutcard />

          </Col>
          <Col
            md={5}
            style={{ paddingTop: "120px", paddingBottom: "20px" }}
            className="about-img"
          >
            <img src={laptopImg} alt="about" className="img-fluid" />
            <p style={{ color: "rgb(155 126 172)" }}>
            "Too many languages to learn, too little time to live"{" "}
          </p>
          </Col>
        </Row>
        

        
<br /><br />
        <h1 className="project-heading">
          Professional <strong className="purple">Skillset </strong>
        </h1>

        <Techstack />

        <h1 className="project-heading">
          <strong className="purple">Tools</strong> I use
        </h1>
        <Toolstack />

        <Github />
      </Container>
      <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
             E<span className="small">XPERIENCES</span><strong> <span className="purple">(Technical)</span></strong> 
            </h1>
      <VerticalTimeline>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'rgb(105, 17, 143)', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(105, 17, 143)' }}
          iconStyle={{ background: 'rgb(105, 17, 143)', color: '#fff' }}

        >
          <h3 className="vertical-timeline-element-title">Summer Research Intern</h3>
          <h5 className="vertical-timeline-element-subtitle">STARC, Bengaluru</h5>
          <h5 className="vertical-timeline-element-subtitle">Jun '23 - Present</h5>
          <p>
          Machine learning models to analyse characteristics of ice hockey players and teams through the use of statistics and machine
          learning tools to understand the effects on their performance.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'linear-gradient(to left top, rgb(83, 10, 110), rgb(83, 10, 110))', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(83, 10, 110)' }}

          iconStyle={{ background: 'rgb(83, 10, 110)', color: '#fff' }}

        >
          <h3 className="vertical-timeline-element-title">Open-Source Contributor</h3>
          <h5 className="vertical-timeline-element-subtitle">Girlscript Summer of Code</h5>
          <h5 className="vertical-timeline-element-subtitle">May '23 - Jul '23</h5>
          <p>
          Drive webinars, boot camps and events for MyCaptain on your campus. Brainstorming and designing campaigns and events.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'rgb(66, 4, 89)', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(66, 4, 89)' }}

          iconStyle={{ background: 'rgb(66, 4, 89)', color: '#fff' }}

        >
          <h3 className="vertical-timeline-element-title">Ambassadorship in Marketing</h3>
          <h5 className="vertical-timeline-element-subtitle">My Captain, NSRCEL- IIM Bangalore Incubated Company</h5>
          <h5 className="vertical-timeline-element-subtitle">Jan '23 - Mar '23</h5>
          <p>
          Drive webinars, boot camps and events for MyCaptain on your campus. Brainstorming and designing campaigns and events.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'rgb(47, 3, 64)', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(47, 3, 64)' }}

          iconStyle={{ background: 'rgb(47, 3, 64)', color: '#fff' }}
        >
        <h3 className="vertical-timeline-element-title">Open-Source Contributor and Maintainer</h3>
        <h5 className="vertical-timeline-element-subtitle">Hacktoberfest</h5>
        <h5 className="vertical-timeline-element-subtitle">October 2022</h5>
        <p>
        Contributed to projects/repositories in IOT and Web development and maintained 2 repositories consisting of projects in the Web
        Development and Data Structure, under Hacktoberfest 2022.
        </p>
        </VerticalTimelineElement>

      </VerticalTimeline>
      <br /><br />

      <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
                   P<span className="small">OSITION OF</span><strong> <span className="purple">RESPONSIBILITY</span></strong> 
                  </h1>
      <VerticalTimeline>

      <VerticalTimelineElement
      className="vertical-timeline-element--work"
      contentStyle={{ background: 'rgb(105, 17, 143)', color: '#fff' }}
      contentArrowStyle={{ borderRight: '7px solid  rgb(105, 17, 143)' }}
      iconStyle={{ background: 'rgb(105, 17, 143)', color: '#fff' }}

      >
      <h3 className="vertical-timeline-element-title">Shunya Math Club PESU ECC</h3>
      <h5 className="vertical-timeline-element-subtitle">Oct 22 - Present</h5>
      <p>
      <strong><h5>Campus Head (June 23–Present)</h5></strong>
      Logistics Head (Jan 23-June 23)<br />
      Operations and Logistics Team (Jun '23 - Present)<br />

      </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
      className="vertical-timeline-element--work"
      contentStyle={{ background: 'linear-gradient(to left top, rgb(83, 10, 110), rgb(83, 10, 110))', color: '#fff' }}
      contentArrowStyle={{ borderRight: '7px solid  rgb(83, 10, 110)' }}

      iconStyle={{ background: 'rgb(83, 10, 110)', color: '#fff' }}

      >
      <h3 className="vertical-timeline-element-title">IEEE RAS PESU </h3>
      <h5 className="vertical-timeline-element-subtitle">Jan 23-June 23</h5>
      <p>
      Sponsorship Head 
      </p>
      </VerticalTimelineElement>

      <VerticalTimelineElement
      className="vertical-timeline-element--work"
      contentStyle={{ background: 'rgb(66, 4, 89)', color: '#fff' }}
      contentArrowStyle={{ borderRight: '7px solid  rgb(66, 4, 89)' }}

      iconStyle={{ background: 'rgb(66, 4, 89)', color: '#fff' }}
      >
      <h3 className="vertical-timeline-element-title">Codechef PESU ECC</h3>
      <h5 className="vertical-timeline-element-subtitle">March 23-June 23</h5>
      <p>
      Technical Core Team
      </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
      className="vertical-timeline-element--work"
      contentStyle={{ background: 'rgb(47, 3, 64)', color: '#fff' }}
      contentArrowStyle={{ borderRight: '7px solid  rgb(47, 3, 64)' }}

      iconStyle={{ background: 'rgb(47, 3, 64)', color: '#fff' }}
      >
      <h3 className="vertical-timeline-element-title">HackerSpace PESU ECC</h3>
      <h5 className="vertical-timeline-element-subtitle">Aug 22-Present</h5>
      <p>
      Student Mentor (Technical)
      </p>
      </VerticalTimelineElement>
      </VerticalTimeline>

  </Container>
  );
}

export default About;
