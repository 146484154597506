import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Soham Sarkar </span>
            a <span className="purple">third-year </span> computer science student, problem-solver with a passion for using technology to make a <span className="purple">positive impact</span>  on the world. I am an <strong><span className="purple">open-source enthusiast</span></strong> who is an ardent believer of <strong><span className="purple">Community Superpower</span></strong>. 
            <br/><br/>
            <span style={{ fontSize: "1em", paddingTop: "15px"}}>Areas of <strong className="purple">Interests</strong> : Distributed Systems, Cloud, Networking, Operating Systems, Web Development</span>

            <br /><br />
            {/* I am proficient in <span className="purple">C/C++, Python, Linux,</span> and I am also an <span className="purple">open-source enthusiast</span>. I am currently learning <span className="purple">Data Science, Data Engineering, and DevOps</span>. 
            
            <br /><br /> */}
            Apart from coding, some other activities that I love to do:
          </p>
          
          <ul>
             <li className="about-activity">
               {/* <ImPointRight /> I'm a Numismatist and I love collecting rare coins of the India and the world! */}
               <ImPointRight /> I'm a numismatist, and I love collecting rare coins from India and around the world!
             </li>
             <li className="about-activity">
               {/* <ImPointRight /> I'm an astrophile and love taking photographs of nature in general */}
               <ImPointRight /> I'm an astrophile, a solist, a selenophile, a naturalist,and I love capturing those moments through my lenses.
             </li>
             <br />
             <li className="about-activity">
               {/* <ImPointRight /> Travelling */}
               Hence, I love traveling, finding myself amidst nature, and exploring places to discover ancient coins and yes food obviously hehe 🤭.
             </li>
           </ul>


          
        </blockquote>
        
      </Card.Body>
      
    </Card>
  );
}

export default AboutCard;
